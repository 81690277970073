<template>
    <div class="p-grid">
        <div class="p-col-6 card">
            <h5>{{$t('Taller')}}</h5>
            <div class="p-grid card">
                <div class="p-col-9">
                    <label>{{$t('Nombre del Taller')}}</label>
                    <InputText v-model="benef.taller" type="text" :disabled="!inf.forence" Style="width: 100%"/>
                    <small v-if="!benef.taller" class="p-error">{{$t('required')}}</small>
                </div>
                <div class="p-col-3">
                    <label>{{$t('Nº Días')}}: </label>
                    <InputText v-model="benef.dias" type="number" min="0" :disabled="!inf.forence" Style="width: 100%"/>
                </div>
                <div class="p-col-12">
                    <label>{{$t('Observaciones')}}: </label>
                    <Textarea v-model="benef.observacion" rows="2" :disabled="!inf.forence" Style="width: 100%"/>
                </div>
                <div class="p-col-12">
                    <label>{{$t('Se observó daños ocultos')}}: </label>
                    <Textarea v-model="benef.oculto" rows="2" :disabled="!inf.forence" Style="width: 100%"/>
                    <small v-show="benef.oculto" class="p-error">{{$t('required')}}</small>
                </div>
            </div>			
        </div>
        <div class="p-col-6 card" style="background:#E7E7D9;">
            <h5>{{$t('Cargar Documentos')}}</h5>
            <div class="p-grid card" v-if="inf.forence">
                <div class="p-col-8"> 
					<label>{{$t('Descripción')}}</label>
                    <InputText v-model="descripcion" type="text" Style="width: 100%"/>
                    <small v-show="!descripcion" class="p-error">{{$t('required')}}</small>
                </div>
                <div class="p-col-4"> 
                    <FileUpload class="p-mb-2" :chooseLabel="$t('Subir JPG,JPEG')" uploadLabel="Cargar" mode="basic" :name="img" :url="url.api+'upload.php'"  accept="image/jpeg,image/jpg" :maxFileSize="4000000" @upload="myUploader1(img)" :auto="true" Style="width: 100%"/>
                    <FileUpload class="p-mb-2" :chooseLabel="$t('Subir PDF')" uploadLabel="Cargar" mode="basic" :name="pdf" :url="url.api+'upload.php'"  accept="application/pdf" :maxFileSize="4000000" @upload="myUploader2(pdf)" :auto="true" Style="width: 100%"/>
                    <small class="p-error">{{$t('tamaño máximo')+": 4MB"}}</small><br>
                </div>
                <div class="p-col-12"> 
                    <DataTable :value="anexos" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
						:rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
                        <Column :header="$t('Descripción')">
                            <template #body="slotProps">
                                {{slotProps.data.descripcion}}
                            </template>
                        </Column>
                        <Column :header="$t('Ver')" headerStyle="width: 20%">
                            <template #body="slotProps">
                                <img style="width: 25px;" :src="url.upload+slotProps.data.codigo" v-if="slotProps.data.format=='jpg'"  @click="IMG(url.upload+slotProps.data.codigo)"/>
                                <img style="width: 25px;" src="images/pdf.jpg" v-if="slotProps.data.format=='pdf'" @click="VerPDF(slotProps.data.codigo)"/>
                            </template>
                        </Column>
                        <Column :header="$t('Eliminar')" headerStyle="width: 20%">
                            <template #body="slotProps">
                                <Button :disabled ="$store.state.loading" icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="DeleteDoc(slotProps.data)"/>
                            </template>
                        </Column>
                    </DataTable>
                    <OverlayPanel ref="op1" appendTo="body" :showCloseIcon="true">
                        <img style="width: 350px;" :src="imagen"/>
                    </OverlayPanel>										
				</div>
            </div>
        </div>
        <div class="p-col-12 card">
            <div class="p-grid card">
                <div class="p-col-12"> 
                    <DataTable :value="$store.state.claim.foren" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                        :rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
                        <Column :header="$t('Descripción')">
                            <template #body="slotProps">
                                <InputText v-model="slotProps.data.descripcion" type="text" style="width:100%;"/>
                            </template>
                        </Column>
                        <Column :header="$t('Costo')" headerStyle="width: 10%">
                            <template #body="slotProps">
                                <InputText v-model="slotProps.data.costo" type="number" min="0" style="width:100%;" @change="Calcular"/>
                            </template>
                        </Column>
                        <Column :header="$t('Cantidad')" headerStyle="width: 10%">
                            <template #body="slotProps">
                                <InputText v-model="slotProps.data.cantidad" type="number" min="0" style="width:100%;" @change="Calcular"/>
                            </template>
                        </Column>
                        <Column :header="$t('Total')" headerStyle="width: 10%">
                            <template #body="slotProps">
                                {{slotProps.data.costo*slotProps.data.cantidad}}
                            </template>
                        </Column>
                        <Column :header="$t('De segunda')" headerStyle="width: 10%">
                            <template #body="slotProps">
                                <Checkbox v-model="slotProps.data.segunda" :binary="true"/>
                            </template>
                        </Column>
                        <Column :header="$t('Genérico')" headerStyle="width: 10%">
                            <template #body="slotProps">
                                <Checkbox v-model="slotProps.data.generico" :binary="true"/>
                            </template>
                        </Column>
                        <Column :header="$t('Eliminar')" headerStyle="width: 10%">
                            <template #body="slotProps">
                                <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="Delete(slotProps.data,index)"  :disabled="$store.state.loading"/>
                            </template>
                        </Column>
                    </DataTable>
                    <Button :disabled ="$store.state.loading" :label="$t('Agregar')" icon="pi pi-plus-circle" class="p-button-secondary"  style="max-width:150px;" @click="Agregar"/>			
                </div>
                <div class="p-col-8">
                    <div class="p-grid card">
                        <div class="p-col-4" style="margin-top:10px">
                            <strong>Subtotal:</strong> 
                        </div>
                        <div class="p-col-8">
                            <strong>{{benef.subtotal}}</strong> 	
                        </div>
                        <div class="p-col-4" style="margin-top:10px">
                            <strong>{{$t('Impuesto')}} % :</strong> 	
                        </div>
                        <div class="p-col-6">
                            <InputNumber id="horizontal" v-model="benef.impuesto" showButtons buttonLayout="horizontal" 
                                decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success" incrementButtonIcon="pi pi-plus" 
                                decrementButtonIcon="pi pi-minus" min="0" style="width:50%;margin:3px" />
						</div>
                        <div class="p-col-2" style="margin-top:10px">
                            <strong>{{(parseFloat(benef.subtotal)*parseFloat(benef.impuesto/100)).toFixed(2)}}</strong> 
                        </div>
                        <div class="p-col-4" style="margin-top:10px">
                            <strong>{{$t('Tiempo perdido')}}:</strong> 	
                        </div>
                        <div class="p-col-6">
                            <InputNumber id="horizontal" v-model="benef.timeloss" showButtons buttonLayout="horizontal" 
                                decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success" incrementButtonIcon="pi pi-plus" 
								decrementButtonIcon="pi pi-minus" min="0" mode="currency" :currency="$store.state.currency" style="width:50%;margin:3px;"/>
						</div>
                        <div class="p-col-2" style="margin-top:10px">
                            <strong>{{(parseFloat(benef.dias)*parseFloat(benef.timeloss)).toFixed(2)}}</strong> 
                        </div>
                        <div class="p-col-4" style="margin-top:10px">
                            <strong>{{$t('Labor de Trabajo')}}:</strong> 
                        </div>
                        <div class="p-col-4">
                            <InputNumber id="horizontal" v-model="benef.trabajo" showButtons buttonLayout="horizontal" 
                                decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success" incrementButtonIcon="pi pi-plus" 
								decrementButtonIcon="pi pi-minus" min="0" mode="currency" :currency="$store.state.currency" style="width:50%;margin:3px;"/>
						</div>
                        <div class="p-col-2" style="margin-top:10px">
                            <strong>{{parseFloat(benef.trabajo).toFixed(2)}}</strong> 
                        </div>
                        <div class="p-col-4">
                            <strong>{{$t('Labor de Pintura')}}:</strong> 
                        </div>
                        <div class="p-col-4">
                            <InputNumber id="horizontal" v-model="benef.pintura" showButtons buttonLayout="horizontal"
                                decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success" incrementButtonIcon="pi pi-plus" 
								decrementButtonIcon="pi pi-minus" min="0" mode="currency" :currency="$store.state.currency" style="width:50%;margin:3px;"/>
						</div>
                        <div class="p-col-2" style="margin-top:10px">
                            <strong>{{parseFloat(benef.pintura).toFixed(2)}}</strong> 	
                        </div>
                        <div class="p-col-10" style="margin-top:10px">
                            <h3><strong>{{$t('Total')}}:</strong></h3>
                        </div>
                        <div class="p-col-2" style="margin-top:10px">
                            <h3><strong>{{(parseFloat(benef.subtotal)*parseFloat(1+benef.impuesto/100)-parseFloat(benef.descuento)+parseFloat(benef.trabajo)+parseFloat(benef.pintura)+parseFloat(benef.dias)*parseFloat(benef.timeloss)).toFixed(2)}}</strong></h3>
						</div>
                    </div>                  
                </div>
            </div>
        </div>
	</div>
</template>

<script>
	import { useI18nPlugin } from '@unify/vuex-i18n';
	import url from "@/service/_URL";
	import API from "../service/API";
	const Consulta = new API('Reclamos','Claim2');
	import Token from "uuid-token-generator";

	export default {
        props: {
            datos: {
                type: Object,
                default: null,
            },
        },
        data() {
			return {
				i18n: null,
                url: url,
                inf: this.datos,
                benef: this.datos.beneficiario,
                anexos: this.datos.anexos,
                descripcion: null,
                img: null,
				pdf: null,
                selectedCustomers: null,
                filters: {},

			}
		},
		created() {
            this.$store.state.claim.foren = this.datos.foren;
			this.i18n = useI18nPlugin();
            const tokgen = new Token(256);
			this.img = tokgen.generate();
			this.pdf = tokgen.generate();


		},
        methods: {
            Agregar() {
				this.$store.state.claim.foren.push({id: 0, descripcion:  '', costo: 0 , cantidad: 0, segunda: false, generico: false});
			},
			Calcular(){
				var subtotal = 0;
				this.$store.state.claim.foren.forEach(element => {
					subtotal = (parseFloat(subtotal) + parseFloat(element.costo*element.cantidad)).toFixed(2);
				});
                this.benef.subtotal = subtotal;
			},
            Delete(selec) {
				this.$store.state.claim.foren = this.$store.state.claim.foren.filter(val => val !== selec);
				this.Calcular();
			},
			DeleteDoc(selec) {
				this.anexos = this.anexos.filter(val => val !== selec);
			},
            IMG(imegan){
				this.imagen = imegan;
				this.toggle1(event);
			},
			toggle1(event) {
				this.$refs.op1.toggle(event);
			},
			VerPDF(document){
				window.open(url.upload+document+'.pdf', '_blank');
			},
            myUploader1(img) {
				if (this.descripcion == null || this.descripcion == '') {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: this.$t('Error en Descripción'), life: 10000});
				} else {
					Consulta.Procesar('RevFoto',{
						foto: img,
						descripcion: this.descripcion,
						claim: this.inf.id,
					}).then(response => {
						if(response.result){					
							this.anexos.push({
								id: 0, 
								claim: this.inf.id,
								descripcion:  this.descripcion, 
								format: 'jpg', 
								codigo: response.result
							});
							this.descripcion = null;
							const tokgen = new Token(256);
							this.img = tokgen.generate();
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: this.$t('Advertencia'), 
								detail: this.$t('Error en Foto'), 
								life: 3000
							});
						}
					});
				}
			},
			myUploader2(pdf) {
				if (this.descripcion == null || this.descripcion == '') {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: this.$t('Error en Descripción'), life: 10000});
				} else {
					Consulta.Procesar('RevPDF',{
						foto: pdf,
						descripcion: this.descripcion,
						claim: this.inf.id,
					}).then(response => {
						if(response.result){					
							this.anexos.push({
								id: 0, 
								claim: this.inf.id,
								descripcion:  this.descripcion, 
								format: 'pdf', 
								codigo: pdf
							});
							this.descripcion = null;
							const tokgen = new Token(256);
							this.pdf = tokgen.generate();
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: this.$t('Advertencia'), 
								detail: this.$t('Error en Foto'), 
								life: 3000
							});
						}
					});
				}
			},


        }
	}
</script>
